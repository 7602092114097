import React from 'react';
import Contact from './contact';

import SocialIcon from './social_icon';

const Header = ({ name, role, contacts, social }) => (
  <header className="border-b border-neutral-300 pb-2 md:flex items-center justify-between">
    <div>
      <h1 className="text-primary-500 text-4xl md:text-5xl font-bold tracking-wide leading-tight">
        {name}
      </h1>
      <h2 className="font-light text-lg md:text-2xl text-primary-900 leading-relaxed tracking-widest">
        {role}
      </h2>
    </div>

    <div className="mt-5 md:mt-0 md:border-l md:border-neutral-300 md:pl-4">
      {contacts &&
        Object.keys(contacts).map(key => (
          <Contact key={key} field={key} value={contacts[key]} />
        ))}
      {social && (
        <div className="flex items-left mr-2">
          {social.map(item => (
            <a
              key={item.service}
              className="footer-social-link"
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              title={item.service}
            >
              <SocialIcon type={item.service} />
            </a>
          ))}
        </div>
      )}
    </div>
  </header>
);

export default Header;
